.App{
    margin-top: 0;
    font-family:"textFont";
    overflow: hidden;
    background-color: rgb(235, 238, 240);
}
body {
  margin: 0;
  padding: 0;
}

@font-face {
    font-family: headerFont;
    src: url(.//fonts/Lintsec/Lintsec.ttf);
  }

  @font-face {
    font-family: bottomFont;
    src: url(.//fonts/felipa/Felipa-Regular.otf);
  }
  @font-face {
    font-family: textFont;
    src: url(.//fonts/roboto-2014/Roboto-Medium.ttf);
  }
  

  :root {
    --wave-height: 50px;
  }
  
  @media (max-width: 768px) {
    :root {
      --wave-height: 25px;
    }
  }
  
  @media (max-width: 480px) {
    :root {
      --wave-height: 15px;
    }
  }
  
  @keyframes wave {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(calc(var(--wave-height) * -1)); }
  }
  .container {
    position: relative;
    width: 500px;
    height: 150px;
  }
  
  #title {
    position: absolute;
    font-family: "headerFont";
    display: flex;
    white-space: nowrap;
    font-weight: bold;
    letter-spacing: 5px;
    text-align: center;
    overflow: hidden;
    top: 50px;
    color: #f86f03;
    text-shadow: 3px 3px #828181;
    animation: textreveal 3s  infinite alternate;
}

@keyframes textreveal {
    0%, 10%, 20% { width: 0; }
    40%,60%,80%, 100% { width: 700px; }
}
  #title-topics {
    position: absolute;
    font-family: "headerFont";
    display:flex;
    white-space: nowrap;
    font-weight: bold;
    letter-spacing: 5px;
    text-align: center;
    overflow:hidden;
    top: 50px;
    color: #f86f03;
    text-shadow: 3px 3px #828181;
    animation: textrevealtopics 5s infinite alternate;
  }
  
  

  @keyframes textrevealtopics {
    0% { width: 0; }
    10% { width: 0; }
    40% { width: 800px; }
    100% { width: 800px; }
  }

 